import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { CoursesService } from 'src/app/services/courses.service';
import { SurveysService } from 'src/app/services/surveys.service';
import { FormattedCourse, formatCourse } from '../../../../my-courses.utils';
import { AuthService } from 'src/app/services/auth.service';
import { NewTableData } from 'src/app/components/new-table/new-table.component';
import { RequirementsService } from 'src/app/services/requirements.service';
import { UpdateRequirementModalComponent } from '../update-requirement-modal/update-requirement-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { UpdateStatusModalComponent } from '../update-status-modal/update-status-modal.component';
import { CatalogCourseDetailsComponent } from 'src/app/routes/manage-courses/components/course-catalog/components/catalog-course-details/catalog-course-details.component';
import { Router } from '@angular/router';
import { GeneralDataCell, StatusDataCell } from 'src/app/components/new-table/table-cell/table-cell.component';

@Component({
  selector: 'app-courses-list',
  templateUrl: './courses-list.component.html',
  styleUrls: [ './courses-list.component.scss' ],
})
export class CoursesListComponent {
  @Input() user: any;
  courseSubscription: Subscription;
  singleCourseSubscription: Subscription;
  courseRegistrationSubscription: any;
  surveySubscription: any;
  courses: any
  formattedCourses: FormattedCourse[] = [];
  userRequirements: any[] = [];
  isLoading = false;
  selectedItemsPerPage = 25;
  offset = 0;
  sortColumn = 'dateOffered';
  sortColumnDirection: 'ASC' | 'DESC' = 'ASC';

  loggedInUser: any;
  loggedInUserPermission: any;


  tableData: NewTableData = {
    title: 'Course List',
    rowStyling: 'default',
    noDataFoundMessage: 'No course registrations found.',
    noDataButtonText: 'Register for Courses',
    columnTitles: [
      {
        name: 'Course',
        sortable: true,
        sortByProp: 'courseName',
      },
      {
        name: 'Date & Time',
        sortable: true,
        sortByProp: 'dateOffered',
        isDefaultSort: true,
        sortDirection: 'ASC',
        // width: '175px',
      },
      {
        name: 'Location',
        sortable: true,
        sortByProp: 'location',
        // width: '175px',
      },
      {
        name: 'Requirement',
        sortable: true,
        sortByProp: 'requirement',
        // width: '175px',
      },
      {
        name: 'Hours',
        sortable: true,
        sortByProp: 'adjustedValue',
        // width: '114px',
        // align: 'center',
      },
      {
        name: 'Status',
        sortable: true,
        sortByProp: 'userCourseStatus',
        // width: '150px',
      },
    ],
    data: [],
    hasBurgerMenu: true,
    // hamburgerMenuWidth: '74px',
    burgerContent: [
      { content: 'View Course Details', eventName: 'viewDetails' },
      {
        content: 'Take Survey',
        eventName: 'takeSurvey',
        displayCondition: (itemId: number) => this.itemInSurveyStatus(itemId),
      },
      {
        content: 'Update Course Status',
        eventName: 'updateStatus',
        displayCondition: (itemId: number) => this.userCanUpdateStatus(itemId),
      },
      {
        content: 'Change Requirement',
        eventName: 'changeRequirement',
        displayCondition: (itemId: number) => this.userCanUpdateStatus(itemId),
      },
    ],

    meta: {
      totalItems: 0,
      itemsPerPage: 0,
      curPage: 0,
      totalPages: 0,
      usePagination: false,
      links: {
        prev: '',
        next: '',
        first: '',
        last: '',
        self: '',
      },
    },
  }


  constructor(
    public dialog: MatDialog,
    private courseService: CoursesService,
    private surveysService: SurveysService,
    private requirementService: RequirementsService,
    private AuthService: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.searchForCourses()

    this.AuthService.user$.subscribe((user: any) => {
      if (!user) return;
      this.loggedInUser = user;
    });

    this.AuthService.permissions$.subscribe((permissions: any) => {
      if (!permissions) return;
      this.loggedInUserPermission = permissions;
    });

    this.courseSubscription = this.courseService.coursesData$.subscribe((courses: any) => {
      if (!courses) return;

      this.courses = courses;
      this.formattedCourses = courses.rows.map((course: any) => formatCourse(course));

      this.updateTableData();
      this.isLoading = false;
    })

    this.requirementService.getUserRequirements(this.user.id, false).subscribe((reqRes: any) => {
      const userRequirements = reqRes.data.rows;

      this.userRequirements = userRequirements;
    });

  }

  updateTableData() {
    const courses = this.formattedCourses;
    const courseData = courses.map((course: FormattedCourse) => {
      const courseId = course.courseItemId;

      const courseCell: GeneralDataCell = { mainText: course.name, subText: courseId };
      const dateAndTimeCell: GeneralDataCell = {
        mainText: course.dateOffered || '-',
        subText: course.time || '-',
      }
      const locationCell: GeneralDataCell = { mainText: course.location, subText: course.room };

      const requirementCell: GeneralDataCell = { mainText: '' };

      if (course.parentRequirement) {
        requirementCell.mainText = course.parentRequirement;
        requirementCell.subText = course.requirement;
      } else {
        requirementCell.mainText = course.requirement;
        requirementCell.subText = '-'
      }

      const hoursCell: GeneralDataCell = { mainText: course.hours || '-' };

      const statusCell: StatusDataCell = {
        statusKid: course.statusKid,
        statusDisplay: course.statusDisplay,
        statusType: 'courseRegistration',
        forceUppercase: true,
      };


      return {
        itemId: courseId,
        columnData: [
          courseCell,
          dateAndTimeCell,
          locationCell,
          requirementCell,
          hoursCell,
          statusCell,
        ],
      };
    });

    const meta: NewTableData['meta'] = {
      curPage: this.courses.pagination.curPage,
      itemsPerPage: this.courses.pagination.totalPerPage,
      links: this.courses.links,
      totalItems: this.courses.pagination.totalItems,
      totalPages: this.courses.pagination.totalPages,
      usePagination: true,
    }
    if (meta.totalPages > 1) {
      meta.usePagination = true;
    }

    this.tableData.meta = meta;
    this.tableData.data = courseData;


  }

  calculateOffset(page: number): number {
    return (page - 1) * this.selectedItemsPerPage;
  }

  // Pagination event handlers
  onPageChanged(page: number) {
    const offset = this.calculateOffset(page);
    this.offset = offset;
    this.searchForCourses()
  }

  onItemsPerPageChange(itemsPerPage: number) {
    this.selectedItemsPerPage = itemsPerPage;
    this.offset = 0;
    this.searchForCourses()
  }

  // paginationEvent = (pageDirection: string) => {
  //   this.courseService.getPagination(pageDirection);
  // }

  searchForCourses() {
    this.isLoading = true;
    this.courseService.getPDPlanCourseRegistrations(
      {
        userId: this.user.id,
        sortDirection: this.sortColumnDirection,
        sortColumn: this.sortColumn,
        includedAssociations: 'Survey',
      },
      this.selectedItemsPerPage,
      this.offset,
    );
  }

  // #region Modal Handlers
  openRequirementsModal = (registration: any) => {
    const data = {
      userRequirements: this.userRequirements,
      course: registration,
      selectedUser: this.user,
      selectedRequirementId: registration.selectedRequirementId,
    }
    // this.popup = '';
    const dialog = this.dialog.open(UpdateRequirementModalComponent, { data: data });

    dialog.afterClosed().subscribe((result: any) => {

      this.isLoading = true;
      this.searchForCourses();
    });
  }

  openUpdateStatusModal = (registration: any) => {
    const dialog = this.dialog.open(UpdateStatusModalComponent, { data: registration });
    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.isLoading = true;

        this.searchForCourses();
      }
    });
  }

  openCourseDetailsModal = (registration: any) => {
    this.dialog.open(CatalogCourseDetailsComponent, { data: { courseItemId: registration.courseItemId, userRequirements: this.user.Requirements } })
  }

  itemInSurveyStatus(registrationId: number) {
    const course = this.courses.rows.find((course: any) => {
      return course.courseItemId == registrationId;
    });

    return course.currentStatusKid === 'TAKE_SURVEY';
  }

  userCanUpdateStatus(registrationId: number) {
    const course = this.courses.rows.find((course: any) => {
      return course.courseItemId == registrationId;
    });
    const loggedInUserHasPermission = this.loggedInUserPermission.VALIDATE_ATTENDANCE;
    const loggedInUserIsOwner = course.userId === this.loggedInUser.id;

    return loggedInUserIsOwner || loggedInUserHasPermission;
  }
  // #endregion

  // #region Event Handlers
  handleRowClicked(itemId: number) {
    const registration = this.formattedCourses.find((course: FormattedCourse) => course.courseItemId === itemId);

    const currentStatus = registration.statusKid;
    if (currentStatus === 'TAKE_SURVEY') {
      return this.updateSurveyStatus(registration.id);
    }

    this.openCourseDetailsModal(registration);
    // console.log(event);
  }

  handleBurgerMenuClicked({ eventName, itemId }: { eventName: string, itemId: number }) {
    const course = this.formattedCourses.find((course: FormattedCourse) => course.courseItemId === itemId);
    if (eventName === 'changeRequirement') {
      this.openRequirementsModal(course);
    }

    if (eventName === 'updateStatus') {
      this.openUpdateStatusModal(course);
    }

    if (eventName === 'viewDetails') {
      this.openCourseDetailsModal(course);
    }
    if (eventName === 'takeSurvey') {
      const registrationId = course.id

      this.updateSurveyStatus(registrationId);
    }
  }

  updateSurveyStatus = (registrationId: number) => {
    const course = this.courses.rows.find((course: any) => course.id === registrationId);
    const url = course?.Course?.Survey.url;

    if (!!url) {
      window.open(url, '_blank');
    }

    this.courseRegistrationSubscription = this.courseService.updateFromSurvey(course.id).subscribe({
      next: () => {
        this.searchForCourses();
      },
      error: (error: any) => {
        console.error(error);
      },
    });
  }

  handleSortClicked({ columnName, sortDirection }: { columnName: string;  sortDirection: 'ASC' | 'DESC' }) {
    this.sortColumn = columnName;
    this.sortColumnDirection = sortDirection;
    this.offset = 0;

    this.searchForCourses()
  }

  handleNoDataButtonClicked() {
    // here we want to redirect to the course catalog
    this.router.navigate([ '/manage-courses/course-catalog' ])
  }
  // #endregion
}
